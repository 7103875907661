import React from 'react'
import PropTypes from 'prop-types'
// import Particles from 'react-particles-js';
// import styles from '../styles/particles.css';

const Footer = ({ copyrights }) => (
  <footer>
    {/* <Particles className="footerParticlesContainer"
              params={{
                "particles": {
                  "number": {
                    "value": 160,
                    "density": {
                      "enable": true,
                      "value_area": 800
                    }
                  },
                  "color": {
                    "value": "#ffffff"
                  },
                  "shape": {
                    "type": "star",
                    "stroke": {
                      "width": 0,
                      "color": "#000000"
                    },
                    "polygon": {
                      "nb_sides": 5
                    },
                  },
                  "opacity": {
                    "value": 1,
                    "random": true,
                    "anim": {
                      "enable": true,
                      "speed": 1,
                      "opacity_min": 0,
                      "sync": false
                    }
                  },
                  "size": {
                    "value": 3,
                    "random": true,
                    "anim": {
                      "enable": false,
                      "speed": 4,
                      "size_min": 0.3,
                      "sync": false
                    }
                  },
                  "line_linked": {
                    "enable": false,
                    "distance": 150,
                    "color": "#ffffff",
                    "opacity": 0.4,
                    "width": 1
                  },
                  "move": {
                    "enable": true,
                    "speed": 1,
                    "direction": "none",
                    "random": true,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                      "enable": false,
                      "rotateX": 600,
                      "rotateY": 600
                    }
                  }
                },
                "interactivity": {
                  "detect_on": "canvas",
                  "events": {
                    "onhover": {
                      "enable": true,
                      "mode": "bubble"
                    },
                    "onclick": {
                      "enable": true,
                      "mode": "repulse"
                    },
                    "resize": true
                  },
                  "modes": {
                    "grab": {
                      "distance": 400,
                      "line_linked": {
                        "opacity": 1
                      }
                    },
                    "bubble": {
                      "distance": 250,
                      "size": 0,
                      "duration": 2,
                      "opacity": 0,
                      "speed": 3
                    },
                    "repulse": {
                      "distance": 400,
                      "duration": 0.4
                    },
                    "push": {
                      "particles_nb": 4
                    },
                    "remove": {
                      "particles_nb": 2
                    }
                  }
                },
                "retina_detect": true
              }}
            />
              {copyrights ? (
      <div
        dangerouslySetInnerHTML={{
          __html: copyrights,
        }}
      /> */}
    {/* ) : ( */}
      <>
      <span className="footerInfo">
        <span className="footerCopyrights">
          © 2021 Built by <a href="https://vagelis.dev">Vagelis</a> 😬 using <a href="https://www.gatsbyjs.org" target="_blank"> Gatsby</a>, <a href="https://reactjs.org/
" target="_blank">React</a>, <a href="https://daringfireball.net/projects/markdown/" target="_blank">Markdown</a>, <a href="https://radoslawkoziel.pl" target="_blank">panr's starter</a>, <a href="https://code.visualstudio.com/" target="_blank">VS Code</a>
        </span>
        <span className="footerCopyrights">
          Hosted for free on <a href="https://netlify.com" target="_blank">Netlify</a> ❤️
        </span>
        </span>
      </>
    {/* )} */}
  </footer>
)

Footer.propTypes = {
  copyrights: PropTypes.string,
}

export default Footer
