import React from "react";
import PropTypes from "prop-types";
import ReactFancyBox from "react-fancybox";
import "../styles/fancybox.css";
import style from "../styles/gallery.module.css";
import shuffle from "shuffle-array";

const ImgGallery = ({ props, images }) => {
  const random_images = shuffle(images);
  return (
    <>
    { typeof window !== 'undefined' &&
      <div className={style.gallery_container}>
        {random_images.map(image => (
          <ReactFancyBox
            thumbnail={image.childImageSharp.fluid.src}
            image={image.childImageSharp.fluid.src}
            showCloseBtn={false}
          />
        ))}
      </div>
    }
    </>
  );
};
ImgGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};
export default ImgGallery;
